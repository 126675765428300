@import "../variables.scss";


.projectHoverCursor {
  max-width: 300px;
  pointer-events: none;
  display: none;
  z-index: 1000000;
}

.Projects {

  width: 100vw;
  padding: $s10 0;
  justify-content: center;
  box-sizing: border-box;

  .sectionTitle{
    margin: $s5;
  }

  .projectsContainer {

    section {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      scroll-snap-align: center;
      perspective: 500px;
      padding: 0 $s10;
      overflow: hidden;

      .projectHeading{
        position: absolute;
        top: 40%;
        left: calc($s1 * -30);
        transform: rotateZ(-90deg);
        font-family: $billboard;
        opacity: 0.35;
        color: white;
        font-size: 96px;
      }

      h3{
        position: relative;
        left: -$s5;
        font-size: medium;
        // mix-blend-mode: exclusion;
      }

      .projectYear{
        position: absolute;
        bottom: 0;
        right: $s10;
        mix-blend-mode: difference;
        font-family: $billboard;
        opacity: 0.5;
        font-size: 128px;
      }

      img{
        transition: all 0.25s;
        &:hover{
          transform: rotateZ(5deg);
        }
      }
    }
    
    section > div {
      max-width: 800px;
      position: relative;
      max-height: max(90vh, 750px);
      margin: 20px 0;
      background: var(--white);
      overflow: hidden;

      .projectBanner{
        max-width: 100%;
      }


    }
  }

  .project {
    max-width: 100%;
    min-width: 0;
    padding: $s10 $s8;
    box-sizing: border-box;
    display: grid;
    justify-content: center;
    align-content: center;


    img {
      max-height: 500px;
      max-width: 100%;
    }

    &:last-child {
      border: none !important;
      border-bottom: unset;
    }

    // h1 {
    //   text-align: left;
    //   padding-left: $s10;
    //   width: calc(100vw - $s10 * 2);
    //   justify-self: center;
    // }

    .projectDetails {

      display: grid;
      gap: $s10;


      h1 {
        display: grid;
        justify-content: center;

        * {
          position: relative;
          right: 40vw;
        }
      }

      .markee {
        display: flex;
        align-items: center;
        gap: $s10;

        img {
          width: 200px;
        }


      }

      .projectTitle {
        width: max-content;
        display: flex;
        align-items: center;
        gap: $s10;

      }
    }
  }
}


.markee:hover .projectHoverCursor {
  display: block;
}

@media screen and (max-width: $largeScreen) {
  .Projects {
    .projectsContainer{
      section{
        h2{
          font-size: $header3;
        }

        .projectYear{
          font-size: $header4;
        }
      }
    }
  }
}

@media screen and (max-width: $tabletScreen) {
  .Projects {
    .projectsContainer{
      section{
        display: grid;
        padding: 0 $s5;
        align-items: end;
        min-height: 100vh;
        height: unset;
        h2{
          font-size: $header3;
        }

        .projectYear{
          font-size: $header4;
        }
      }
    }
  }
}