@import "../../variables.scss";

.button {
    position: relative;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: $s10;
    height: $s10;
    color: $primary-text-colour;
    gap: $s1;

    .spinner{
        animation-name: infiniteRotation;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    img {
        position: absolute;
        left: calc(90% - $s2);
        transition: all 0.25s;
    }

    &:hover {
        img {
            left: calc(90% - $s1);
        }
    }
}


@keyframes infiniteRotation {
    0% {
      transform: rotateZ(0deg);
    }
  
    100% {
      transform: rotateZ(360deg);
    }
  }