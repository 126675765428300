@import "../../variables.scss";

.Nav {
  display: flex;
  grid-template-columns: 1fr 2fr;
  justify-content: space-between;
  padding: $s4 $s8;
  z-index: 11;
  // border-bottom: 1px solid $panel-background-colour;



  .rightSection {
    display: flex;
    justify-content: flex-end;
    gap: $s10;

    .ContactLinks {
      display: grid;
      justify-content: end;

      ul {
        display: flex;
        list-style: none;
        flex-wrap: wrap;
        margin: 0 !important;
        padding: 0;
        align-content: center;



        li {
          transition: all 0.15s;
          display: flex;
          align-items: center;
          margin-right: $s4;

          a {
            color: $primary-text-colour;
            text-decoration: none;
            display: flex;
            align-items: center;

            span {
              display: flex;
              align-items: center;
            }

            svg {
              transition: all 0.25s;
            }

            &:hover {
              svg {
                transform: rotateZ(-90deg);
              }
            }
          }
        }
      }
    }

    .hamburger {
      display: flex;
      align-items: center;
      gap: $s1;
      align-self: center;
      justify-self: start;
      z-index: 11;
      position: relative;

      &:hover {
        cursor: pointer;
      }


      svg {
        font-size: $s4;
      }

      // &::after {
      //   content: '';
      //   position: absolute;
      //   background-color: $primary-text-colour;
      //   width: 0;
      //   height: 0;
      //   top: -50%;
      //   left: -50%;
      //   border-radius: 1000px;
      //   transition: all 0.25s;
      //   z-index: -1;
      // }

      // &:hover {
      //   label span {
      //     background-color: black;
      //   }

      //   &::after {
      //     width: 200%;
      //     height: 200%;
      //   }

      // }
    }

    .hamburgermenu {
      background-color: $appBackgroundColour;
      position: fixed;
      left: 0;
      width: 100vw;
      height: 100vh;
      transition: 0.75s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row-reverse;
      overflow: hidden;


      .contacts {
        left: $s10;
        bottom: $s10;
        width: 90vw;
        max-width: 400px;
        height: 100%;
        display: grid;
        align-content: end;
        padding: 0 0 5rem 5rem;

        .email {
          width: 100%;
          display: grid;
          justify-content: center;

          a {
            text-decoration: none;
            color: $primary-text-colour;
            max-width: 90vw;
            height: 150px;
            width: 350px;
            position: relative;
            display: grid;
            justify-items: center;
            align-content: center;

            svg {
              position: absolute;
              width: 100%;

              path {
                transition: all 0.25s;
              }
            }

            &:hover {
              svg path {
                fill: $primary-text-colour !important;

              }
            }
          }
        }

        .socials {
          display: flex;
          gap: 16px;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;

          svg {
            width: 24px;
            height: 24px;
          }

          .socialLinks {
            display: flex;
            gap: $s1;
            align-content: center;

            a {
              display: flex;
              text-decoration: none;
              color: $primary-text-colour;
              font-size: $s2;
            }
          }
        }
      }



      ul {
        display: grid;
        position: relative;
        list-style: none;
        width: 100vw;
        justify-content: end;
        gap: $s2;
        margin: 0;
        padding: $s8;
        align-content: center;

        li {
          justify-self: center;
          font-size: max(calc(100vw/20), $s4);
          display: grid;
          font-family: $headerFont;
          font-weight: bold;
          transition: all 0.25s;
          animation-delay: 1s;

          &:hover {
            .nav-index-number {
              position: relative;
              top: -$s1;
              color: $primary-colour;
            }
          }

          .nav-index-number {
            position: relative;
            top: 0;
            font-size: 16px;
            font-weight: normal;
            margin-right: 4px;
            transition: all 0.25s;
          }


          &::after {
            background-color: $appBackgroundColour;
            transition: all 0.5s;
            animation-delay: 1s;
          }



          a {
            align-self: center;
            text-decoration: none;
            color: $primary-text-colour;
            position: relative;
            transition: all 0.25s;
            top: 0;

            &:hover {

              a {
                top: -10px;
              }
            }
          }
        }
      }
    }
  }

  .NavigationLinks {
    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;

      li {
        transition: all 0.15s;
        font-size: 1.15em;
        margin-right: 2em;

        a {
          color: white;
          text-decoration: none;
        }

        &:hover {
          border-bottom: 2px solid aquamarine;

        }
      }
    }
  }
}

.logoContainer {
  padding: $s4 $s8;
}

@media only screen and (max-width: $tabletScreen) {
  
  .intro-circular{
    display: none;
  }
  
  .Nav {
    .rightSection {
      .hamburgermenu {
        display: grid;
        align-content: center;
        justify-items: center;

        .contacts {
          height: unset;

          padding: 0;
          margin-left: auto;
          margin-right: auto;
        }

        .contacts {
          left: unset;
          right: unset;
        }

        ul {
          justify-content: center;
          padding: $s5;
        }
      }
    }






  }
}

@media only screen and (max-width: $tabletScreen) {
  .Nav {
    padding: $s4 $s3;

    .leftSection {

      .logo {
        height: $s6;
        width: $s6;
      }


    }

    .rightSection {
      .ContactLinks {

        display: none;

        ul {
          display: flex;

          li {
            span {
              font-size: $s2 !important;
            }
          }
        }
      }
    }
  }

  .logoContainer {
    padding: $s4 $s3;
  }
}