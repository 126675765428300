@import "../../variables.scss";

.Intro {
  min-height: calc(100vh - 127px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 $s10;
  gap: $s1;
  max-width: 1350px;
  z-index: 2;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  text-align: left;

  .box {
    border-radius: $s1;
    background-color: #2C2C2C;
    min-height: 150px;
    min-width: 150px;
    padding: $s3;
    box-sizing: border-box;
    transition: all 0.25s;
    overflow: hidden;

    &:hover {
      background-color: rgb(32, 32, 32);
    }
  }

  .leftSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $s1;

    .nameBox {
      grid-column: span 2;
      display: grid;
      align-content: start;

      h1 {
        margin: 0;
        font-size: clamp(1rem, -1.5rem + 8vw, 6rem) !important;
        font-weight: 900;
      }
    }

    .clockBox {
      grid-row: span 2;
      display: grid;


      h1 {
        margin: 0;
        font-size: clamp(1rem, -1.5rem + 8vw, 6rem) !important;
        color: $secondary-text-colour;
      }

      &:hover{

        .location img{
          // max-width: 160%;
          // left: -$s8;
        }
      }

      .location {
        display: flex;
        align-items: center;
        gap: $s1;
        position: relative;
        flex-direction: row-reverse;
        justify-content: center;
        span {
          color: rgb(134, 134, 134);
        }

        img {
          transition: all 0.25s;
          max-width: 130%;
          position: absolute;
          bottom: 0;
          left: -$s6;
        }

      }


    }

    .getInTouchBox {
      display: grid;
      align-content: space-between;
      gap: $s1;

      h5 {
        margin: 0;
      }
    }

    .scrollDownBox {
      background-color: $primary-colour;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      #scrollDownText {
        transform: rotateZ(0deg);
        animation-name: infiniteRotation;
        animation-duration: 10s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        color: black;
        margin: 0;
      }

      .arrowDown {
        position: absolute;
        left: 50%;
        top: 50%;
        translate: -50% -50%;
        animation-name: upDown;
        animation-duration: 1s;
        animation-delay: 4.5s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }
  }

  .rightSection {
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    grid-template-rows: 1fr 0.5fr;

    gap: $s1;

    .jobBox {
      display: grid;
      align-content: space-between;
      gap: $s3;

      h5 {
        margin: 0;
      }

      ul {
        list-style-type: none;
        display: grid;
        gap: $s1;
        padding: 0;
        margin: 0;
        color: $secondary-text-colour;
      }
    }

    .photoBox {
      padding: 0;
      overflow: hidden;
      display: grid;
      align-content: center;
      justify-content: center;
      background-image: url("../../assets/hero-image-2024.webp");
      background-position: center center;
      background-size: cover;
      position: relative;

      &:hover{
        video{
          display: block;
        }
      }

      video{
        position: absolute;
        height: 100%;
        width: 100%;
        display: none;
        object-fit: cover;
      }
    }

    .experienceBox {
      display: flex;
      align-items: center;
      gap: $s2;

      h2 {
        margin: 0;
      }

      span {
        color: $secondary-text-colour;
      }


      h2>span {
        color: $primary-text-colour;
      }


    }

    .stackBox {
      grid-column: span 2;
      position: relative;
      display: grid;
      align-items: center;
      overflow: hidden;

      h5 {
        position: absolute;
        right: $s1;
        transform: rotateZ(90deg);
        opacity: 0.25;
        font-size: $s5;
        font-weight: 900;
      }


      .stackContainer {
        display: flex;
        gap: $s2;
        flex-wrap: wrap;

        span {
          border-radius: $s2;
          padding: $s1 $s2;
          mix-blend-mode: exclusion;
          display: flex;
          align-items: center;
          gap: $s1;
          background: linear-gradient(to left, rgb(82, 82, 82), rgb(46, 46, 46));
          font-weight: 900;
        }

        img {
          height: 24px;
          width: auto;
          object-fit: contain;
          filter: contrast(1.25);
        }
      }
    }
  }
}

.shake{
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite; 
}


@keyframes infiniteRotation {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes infiniteBlink {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes upDown {
  0%{
    transform: translateY(0px);
  }

  25%{
    transform: translateY(-5px);
  }

  50%{
    transform: translateY(0px);
  }

  75%{
    transform: translateY(5px);
  }

  100%{
    transform: translateY(0px);
  }
}




@media only screen and (max-width: $largeScreen) {
  .Intro {
    grid-template-columns: 1fr;
    padding: 0 $s5;

    .leftSection {

      grid-auto-flow: column dense;

      .nameBox {
        grid-column: span 1;
      }

      .clockBox {
        display: none;
      }



    }
  }
}



@media only screen and (max-width: $tabletScreen) {
  .Intro {
    .leftSection {
      .scrollDownBox {
        display: none;
      }

      .getInTouchBox{
        a.button{
          background-color: $primary-colour;  
          color: black;
          border-color: black;
          img{
            display: none;
          }
        }
      }
    }

    .rightSection {
      grid-template-columns: 1fr 1fr;

      .photoBox {
        grid-column: span 1;
      }
    }
  }
}


@media only screen and (max-width: $mobileScreen) {
  .Intro {
    padding: $s2;
    padding-top: 0;

    .box {
      min-height: unset;
    }

    .leftSection {
      grid-template-columns: 1fr;
      grid-auto-flow: unset;

      .nameBox {
        h1 {
          font-size: $s5 !important;
        }
      }

      .getInTouchBox {
        display: flex;
        justify-content: space-between;
        align-items: center;

        

      }

      .scrollDownBox {
        display: none;
      }

    }

    .rightSection {
      grid-template-columns: 1fr;

      .jobBox {
        grid-column: span 2;
      }

      .experienceBox+.experienceBox {
        grid-column: span 2;
      }

      .stackBox {
        h5 {
          display: none;
        }
      }
    }
  }
}



@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}