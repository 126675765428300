@import "../variables.scss";

.Contacts {
  min-height: 100vh;
  display: grid;
  align-content: center;
  justify-items: center;
  margin-top: $s10;
  padding: $s2 $s8;
  position: relative;
  gap: $s4;
  background-color: $appBackgroundColour;
  scroll-snap-align: center;

  h1 {
    margin: 0;
  }

  .form {
    display: grid;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    grid-row-gap: 20px;

    .button {
      margin-left: auto;
    }

    .RecaptchaBox {
      display: grid;
      justify-content: center;
    }



    .nameemail {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
    }


    input {
      height: 50px;
      background-color: rgba(50, 50, 50, 0.75);
      font-size: 1em;
      color: $primary-text-colour;
      padding: 10px;
      border: none;
      font-family: 'Lato', sans-serif;

      &:focus {
        border-bottom: 2px solid $primary-colour;
        outline: none;

      }
    }

    textarea {
      height: 150px;
      background-color: rgba(50, 50, 50, 0.75);
      font-family: 'Lato', sans-serif;
      padding: 10px;
      font-size: 1em;
      color: $primary-text-colour;
      border: none;

      position: relative;

      &:focus {
        border-bottom: 2px solid $primary-colour;
        outline: none;
      }

    }

    button {
      margin-left: auto;
    }

  }
}




@media only screen and (min-width: 500px) and (max-width: 900px) {
  .Contacts {

    .form {
      width: 75%;

      .button {
        margin-right: auto;
      }

      .nameemail {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }
    }

    h3 {
      text-align: center;
      width: 100%;
    }


  }
}



@media only screen and (min-width: 0px) and (max-width: 500px) {
  .Contacts {

    .form {
      width: 100%;

      .button {
        margin-right: auto;
      }

      .nameemail {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }

      button {
        margin-left: auto;
        margin-right: auto;
      }
    }

  }
}


@media screen and (max-width: 500px) {
  .Contacts {
    padding: $s10 $s2;
  }
}