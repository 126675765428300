@import "../../variables.scss";

.ProjectModal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  transform: scale(3);
  transition: all 1s;
  opacity: 0;


  .smallScreenBanner {
    display: none;
    position: relative;
    background-color: white;
    overflow: hidden;
    max-height: 50vh;

    img {
      position: absolute;
    }


    .laptoplayout {
      left: 350px;
      height: 900px;
      width: 650px;
      animation-name: smalllaptopanimation;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      animation-duration: 1s;
      animation-delay: 1s;
      opacity: 0;
    }

    .mobilelayout {
      left: -50px;
      height: 500px;
      animation-name: smallmobileanimation;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      animation-duration: 0.75s;
      animation-delay: 1s;
      opacity: 0;
    }
  }


  .ProjectModalLeftSection {
    text-align: left;
    padding-left: 150px;
    display: grid;
    box-sizing: border-box;
    align-content: center;
    height: 100vh;
    overflow: hidden;
    background-color: $appBackgroundColour;


    .tagsContainer {
      display: flex;
      gap: $s2;
      flex-wrap: wrap;
      mix-blend-mode: difference;

      span {
        border-radius: $s1;
        padding: calc($s1/2) $s1;
        background-color: opacify($color: $primary-text-colour, $amount: 0.5); 
        color: $appBackgroundColour;
      }
    }

    .projectTitle {
      font-size: 5em;
      font-weight: 800;
      position: relative;
      animation-delay: 1.5s;
    }

    .projectDescription {
      animation-delay: 1.5s;

      p {
        line-height: 1.43em;
      }
    }


    p {
      // font-size: 1.45em;
      width: 90%;
      position: relative;
    }



    .projectLinks {
      display: flex;
      margin-bottom: 50px;

      a {
        text-decoration: none;
        color: white;
      }

      .GoToWebsiteLink {
        width: 250px;
        height: 50px;
        display: grid;
        justify-items: center;
        align-content: center;
        margin-top: 50px;
        background-color: $primary-colour;
        transition: all 0.25s;
        animation-delay: 1s;

        &:hover {
          transform: translateY(-20px);

        }

      }

      .CloseLink {
        margin-top: 50px;
        margin-left: 1em;
        height: 50px;
        width: 250px;
        display: grid;
        align-content: center;
        justify-items: center;
        background-color: $panel-background-colour;
        transition: all 0.25s;
        animation-delay: 1.25s;

        &:hover {
          transform: translateY(-20px);

        }
      }


    }


  }


  .ProjectModalRightSection {
    position: relative;
    background-color: white;
    overflow: hidden;

    img {
      position: absolute;
    }

    .laptoplayout {
      left: 350px;
      height: 1200px;
      width: 900px;
      animation-name: laptopanimation;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      animation-duration: 1s;
      animation-delay: 2.75s;
      opacity: 0;
    }

    .mobilelayout {
      left: -50px;
      height: 800px;
      transform: scale(0.75);
      animation-name: mobileanimation;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      animation-duration: 0.75s;
      animation-delay: 2.75s;
      opacity: 0;
    }
  }

}




@media only screen and (min-width:1150px) and (max-width: 1500px) {
  .ProjectModal {
    .ProjectModalLeftSection {
      padding-left: 50px;

      .projectTitle {
        font-size: 3em;
      }

      p {
        font-size: 1.25em;
      }
    }


  }

}



@media only screen and (min-width:768px) and (max-width: 1150px) {
  .ProjectModal {

    grid-template-columns: unset;
    grid-template-rows: auto 1fr;

    .ProjectModalLeftSection {
      padding-left: 50px;
      height: unset;

      .projectTitle {
        font-size: 3em;
      }

      p {
        font-size: 1.25em;
      }
    }


    .ProjectModalRightSection {
      display: none;
    }

    .smallScreenBanner {
      display: block;
      height: 40vh;
    }

  }
}


@media only screen and (min-width:600px) and (max-width: 768px) {
  .ProjectModal {

    grid-template-columns: unset;
    grid-template-rows: auto 1fr;

    .ProjectModalLeftSection {
      padding-left: 25px;
      height: unset;

      .projectTitle {
        font-size: 2em;
        text-align: left;
      }

      p {
        font-size: 1em;
      }
    }


    .ProjectModalRightSection {
      display: none;
    }

    .smallScreenBanner {
      display: block;
      height: 35vh;
    }

  }
}



@media only screen and (min-width:0px) and (max-width: 600px) and (min-height:660px) {
  .ProjectModal {

    grid-template-columns: unset;
    grid-template-rows: auto 1fr;

    .ProjectModalLeftSection {
      padding-left: 10px;
      height: unset;

      .projectTitle {
        font-size: 1.5em;
        text-align: left;
      }

      p {
        font-size: 1em;
        text-align: justify;
      }


      .projectLinks {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 2em;
        padding-right: 1em;

        .GoToWebsiteLink,
        .CloseLink {
          margin-top: 1em;
          width: unset;
        }
      }
    }


    .ProjectModalRightSection {
      display: none;
    }


    .smallScreenBanner {
      display: block;
      height: 35vh;

      .laptoplayout {
        left: 150px;
        height: 450px;
        width: 350px;
        animation-name: smalllaptopanimation;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
        animation-duration: 1s;
        animation-delay: 2.75s;
      }

      .mobilelayout {
        left: -50px;
        height: 400px;
        animation-name: smallmobileanimation;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
        animation-duration: 0.75s;
        animation-delay: 2.75s;
      }
    }




  }
}




@media only screen and (min-width:0px) and (max-width: 600px) and (min-height:0px) and (max-height: 660px) {
  .ProjectModal {

    grid-template-columns: unset;
    grid-template-rows: auto 1fr;

    .ProjectModalLeftSection {
      padding-left: 10px;
      height: unset;

      .projectTitle {
        font-size: 1em;
        text-align: left;
      }

      p {
        font-size: 0.75em;
        text-align: justify;
      }


      .projectLinks {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 2em;
        padding-right: 1em;

        .GoToWebsiteLink,
        .CloseLink {
          margin-top: 1em;
          width: unset;
        }
      }
    }


    .ProjectModalRightSection {
      display: none;
    }


    .smallScreenBanner {
      display: block;
      height: 35vh;

      .laptoplayout {
        left: 150px;
        height: 450px;
        width: 350px;
        animation-name: smalllaptopanimation;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
        animation-duration: 1s;
      }

      .mobilelayout {
        left: -50px;
        height: 400px;
        animation-name: smallmobileanimation;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
        animation-duration: 0.75s;
      }
    }




  }
}







@keyframes laptopanimation {

  0% {
    top: 1000px;
    opacity: 0;
    transform: rotateZ(0deg)
  }

  100% {
    top: 300px;
    opacity: 1;
    transform: rotateZ(-60deg)
  }

}


@keyframes mobileanimation {
  0% {
    top: 230px;
    opacity: 0;
    transform: rotateZ(0deg)
  }

  100% {
    top: 130px;
    opacity: 1;
    transform: rotateZ(30deg)
  }
}


@keyframes smalllaptopanimation {

  0% {
    top: 300px;
    opacity: 0;
    transform: rotateZ(0deg)
  }

  100% {
    top: 0px;
    opacity: 1;
    transform: rotateZ(-60deg)
  }

}


@keyframes smallmobileanimation {
  0% {
    top: 230px;
    opacity: 0;
    transform: rotateZ(0deg)
  }

  100% {
    top: 0px;
    opacity: 1;
    transform: rotateZ(30deg)
  }
}



@keyframes layoutanimation {
  0% {
    top: -230px;
    opacity: 0
  }

  100% {
    top: 0px;
    opacity: 1
  }
}