//typography
$billboard: 150px;
$header1: 102px;
$header2: 72px;
$header3: 51px;
$header4: 36px;
$header5: 26px;
$header6: 18px;



$headerFont: 'Proxima Nova', sans-serif;
$bodyFont: 'Freight Sans';
$billBoardFont: 'Proxima Nova', sans-serif; 


//breakpoints
$fourkscreen: 1800px;
$largeScreen: 1275px;
$tabletScreen: 850px;
$mobileScreen: 550px;



//spacing
$s1: 8px;
$s2: 16px;
$s3: 24px;
$s4: 32px;
$s5: 40px;
$s6: 48px;
$s7: 56px;
$s8: 64px;
$s9: 72px;
$s10: 80px;


//custom spacing variables
$section-spacing: 256px;






//colour pallete
$primary-colour: #db6e00;
$panel-background-colour: #363636;
$panel-background-colour: rgb(53, 53, 53);
$primary-text-colour: #ececec;
$secondary-text-colour: rgb(134, 134, 134);
$appBackgroundColour: #171717;
$linkArrowColour: #838383;