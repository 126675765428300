@import "../variables.scss";

.AboutMe {
  min-height: 100vh;
  display: grid;
  position: relative;
  align-items: start;
  padding: $s10 $s8;
  text-align: left;
  gap: $s10;
  margin-bottom: $s10;
  box-sizing: border-box;
  justify-items: center;
  align-content: center;
  scroll-snap-align: center;

  .background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;

    img{
      height: 100%;
    }
  }

  .photoContainer {
    display: flex;
    justify-content: center;
    gap: 5vw;
    img {
      position: relative;
      max-width: 100vw;
      transform: scale(10),
    }
  }


  .aboutmeparagraph>div>div>span>span {
    background-color: red;
  }

  h1 {
    display: grid;
    align-content: start;

    .stagger-animation-character {
      font-size: calc($header1 - 20px) !important;
    }

    &>div {
      width: min-content;
    }
  }

  .location {
    font-size: $s2 !important;
    font-weight: normal;
  }

  .topSection {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $s2;




    p {
      position: relative;
      top: -$s5;

    }

    img {
      position: absolute;
      bottom: -$s1;
      left: -$s1;
      max-width: 90vw;
    }
  }

  .middleSection {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $s2;

    img {
      position: absolute;
      bottom: -$s1;
      right: -$s1;
      max-height: 275px;
      max-width: 90vw;
      z-index: 1;
    }

    p {
      z-index: 2;
      position: relative;
      bottom: -$s5;
    }
  }

  .bottomSection {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $s2;

    img {
      position: absolute;
      bottom: -$s1;
      left: -$s1;
      max-height: 300px;
      max-width: 90vw;
      z-index: 1;
    }

    p {
      z-index: 2;
      position: relative;
      bottom: -$s5;
    }
  }

  h3 {
    margin-bottom: $s6;
    font-weight: bold;
  }

  p {
    line-height: 1.2em;
    // font-size: $header5;
    max-width: 1500px;
    width: 90vw;
    text-align: center;


    a {
      color: unset;
      text-decoration: unset;
    }
  }
}





@media screen and (max-width: $tabletScreen) {
  .AboutMe {
    padding: $s10 $s2;


    h1 {
      .stagger-animation-character {
        font-size: calc($header3 - 20px) !important;
      }
    }
  }
}