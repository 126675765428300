@import './scss-class.scss';

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;

  *{
    max-width: 100vw;
  }
  
  overflow-x: hidden;
  overflow-y: scroll;
}


.HeroSection {
  scroll-snap-align: start;
}

.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  object-fit: cover;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;

  }

  .gradient-background {
    min-height: 100vh;
    background: linear-gradient(to top, black, #1d1d1d34);
    position: absolute;
    top: 0;
    min-width: 100vw;

  }
}

.HeroSection{
  scroll-snap-align: start;
}

.App {
  text-align: center;
  width: 100vw;
  font-family: $bodyFont;
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: $appBackgroundColour;
  min-height: 100vh;
  color: $primary-text-colour;

  // cursor: none !important;

  // * {
  //   cursor: none;
  // }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.kripsonui-double-mouse-cursor .kripsonui-cursor-trail {
  background-color: $primary-colour !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}




.section {
  min-height: 50vh;
}

.first {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: $appBackgroundColour;
  font-family: $billBoardFont;
  display: grid;
  align-content: center;
  align-items: center;
  justify-items: center;
  z-index: 19;
  animation-name: hidePreloader;
  animation-timing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6);
  animation-duration: 0.1s;
  animation-fill-mode: forwards;
  animation-delay: 4.05s;
  overflow: hidden;

}

.logoContainer {
  width: 100%;
  display: grid;
  justify-items: center;
  align-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 60vh;
  z-index: 20;
  overflow: visible;
  box-sizing: border-box;


}

.logo {
  width: 110px;
  height: 64px;
}

.first {



  .progressBar {
    width: 400px;
    height: 10px;
    // position: absolute;
    // left: $s1;
    // top: $s2;
  }
}

#backgroundVideo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 0.25;
  z-index: -1;
}

@keyframes logoAnimation {
  0% {
    top: 30vh;
    scale: 1;
    left: 45%;
  }

  100% {
    top: 32px;
    scale: 0.5;
    left: $s8;

  }
}

@keyframes moveit {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  1% {
    transform: scale(0);
    opacity: 0.01;
  }

  30% {
    transform: scale(1);
    opacity: 1;
  }

  80% {
    transform: scale(1);
    opacity: 1;
  }

  82% {
    transform: scale(1.05);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes hidePreloader {
  0% {
    height: 100vh;
    width: 100vw;
  }

  1% {
    height: 0;
    width: 0;
  }

  100% {
    height: 0;
    width: 0;
  }
}





@media only screen and (min-width : 0px)and (max-width: 400px) {
  .first img {
    width: 100vw;
  }

}



// .background-animation {
//   position: relative;

//   &::before {
//     content: '';
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 0;
//     height: 10px;

//     background-color: $primary-colour;
//     transition: all 0.25s;

//   }

//   &:hover {
//     &::before {
//       width: 100%;
//     }
//   }
// }

.sectionContainer {
  min-height: 50vh;
}

.splineContainer {
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  min-width: 100vw;
  animation-delay: 5.5s;
  top: 0;
  left: 0;
  min-width: 100vw;
  mix-blend-mode: difference;
  z-index: -1;

}

.appBackground {
  position: absolute;
  top: 0;
  left: 0;
  // background: $appBackgroundColour;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('./assets/background.jpg');
}


@media screen and (max-width: $tabletScreen) {
  .splineContainer {
    display: none;
  }

  .kripsonui-double-mouse-cursor {
    display: none !important;
  }


}



.pseudo-after-animated {
  position: relative;
  animation-name: pseudoAnimated;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  animation-duration: 1s;
  animation-delay: 0.5s;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 110%;
    transform: scale(1.05);
    background-color: $appBackgroundColour;
    animation-name: pseudoAfterAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:after {
    &::before {
      width: 100%;
    }
  }
}

.scaleIn {
  animation-name: scaleInAnimation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transform: scale(0);
}



.stretchedOnHover {
  transition: all 0.25s;
  position: relative;
  width: fit-content;

  &:hover {
    transform: scaleY(1.15);
  }
}


.animatedBorderBottomOnHover {
  position: relative;
  transition: all 0.25s;

  &::after {
    content: '';
    height: 10%;
    width: 0;
    position: absolute;
    bottom: -10%;
    left: 0;
    background-color: $primary-colour;
  }

  &::after {
    transition: all 0.25s;
  }

  &:hover {
    text-decoration: none;

    &::after {
      width: 100%;
    }
  }
}


@keyframes pseudoAnimated {
  0% {
    bottom: -150px;
    opacity: 0;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes pseudoAfterAnimation {
  0% {
    height: 110%;
  }

  100% {
    height: 0%;
  }
}


@keyframes scaleInAnimation {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }

}



@media only screen and (max-width: $mobileScreen) {
  html{
    scroll-snap-type: unset;
  }
}